import React from "react";
import { useOverrides, Override, StackItem, Stack, Section } from "@quarkly/components";
import { Text, Strong, Link } from "@quarkly/widgets";
import BurgerMenu from "./BurgerMenu";
import { MdMenu } from "react-icons/md";
const defaultProps = {
	"margin": "0 0 0 0",
	"box-sizing": "border-box",
	"quarkly-title": "Header",
	"justify-content": "center"
};
const overrides = {
	"stack": {
		"kind": "Stack",
		"props": {}
	},
	"stackItem": {
		"kind": "StackItem",
		"props": {
			"display": "flex",
			"quarkly-title": "Logo",
			"md-width": "75%",
			"width": "25%",
			"height": "90px",
			"xl-width": "250px",
			"padding": "0 16px 0 16px"
		}
	},
	"stackItemOverride": {
		"kind": "Override",
		"props": {
			"slot": "StackItemContent",
			"align-items": "center",
			"xl-min-width": "215px",
			"width": "220px",
			"height": "90px",
			"min-width": "220px",
			"margin": "-16px 0px 0px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/",
			"position": "relative",
			"quarkly-title": "Link",
			"transition": "opacity 200ms ease",
			"cursor": "default",
			"text-decoration-line": "initial",
			"color": "#000000"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"color": "#000000",
			"text-decoration-line": "none",
			"width": "220px",
			"min-width": "220px",
			"position": "static",
			"children": <>
				<Strong
					font="normal 300 40px/1 --fontFamily-googleMontserrat"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					text-align="center"
					color="#000000"
					text-transform="initial"
				>
					kursin.com
					<br />
				</Strong>
				<Strong
					font="normal 300 16px/1 --fontFamily-googleMontserrat"
					text-align="center"
					margin="0px 0px 0px 3px"
					color="#000000"
					text-decoration-line="none"
					text-transform="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
				>
					modern web development
				</Strong>
			</>
		}
	},
	"stackItem1": {
		"kind": "StackItem",
		"props": {
			"width": "75%",
			"display": "block",
			"quarkly-title": "Menu",
			"md-width": "23%",
			"xl-width": "60%"
		}
	},
	"stackItemOverride1": {
		"kind": "Override",
		"props": {
			"slot": "StackItemContent",
			"align-items": "center",
			"justify-content": "center",
			"md-justify-content": "flex-end"
		}
	},
	"burgerMenu": {
		"kind": "BurgerMenu",
		"props": {}
	},
	"burgerMenuOverride": {
		"kind": "Override",
		"props": {
			"slot": "menu",
			"lg-transform": "translateY(0px) translateX(0px)",
			"lg-transition": "transform 400ms ease 0s",
			"md-position": "fixed",
			"display": "flex",
			"md-left": 0,
			"md-top": 0,
			"md-width": "100%",
			"md-height": "100%",
			"padding": "0px 0 0px 0"
		}
	},
	"overrideOverride": {
		"kind": "Override",
		"props": {
			"slot": "item",
			"text-align": "center",
			"padding": "8px 20px 8px 20px",
			"md-padding": "16px 40px 16px 40px",
			"text-transform": "uppercase"
		}
	},
	"overrideOverride1": {
		"kind": "Override",
		"props": {
			"slot": "item-404",
			"lg-display": "none",
			"display": "none"
		}
	},
	"overrideOverride2": {
		"kind": "Override",
		"props": {
			"slot": "item-index",
			"lg-display": "none",
			"display": "none"
		}
	},
	"overrideOverride3": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"md-color": "--dark",
			"md-opacity": ".5",
			"md-transition": "opacity .15s ease 0s",
			"md-hover-opacity": "1",
			"md-active-opacity": "1",
			"md-font": "16px/24px sans-serif",
			"font": "--base",
			"text-decoration-line": "initial",
			"color": "--dark",
			"opacity": ".5",
			"transition": "opacity .15s ease 0s",
			"hover-opacity": "1",
			"letter-spacing": "0.5px",
			"text-transform": "initial"
		}
	},
	"overrideOverride4": {
		"kind": "Override",
		"props": {
			"slot": "link-active",
			"md-opacity": "1",
			"md-cursor": "default",
			"opacity": "1",
			"color": "--primary",
			"cursor": "default"
		}
	},
	"overrideOverride5": {
		"kind": "Override",
		"props": {
			"slot": "link-work",
			"children": "Process"
		}
	},
	"overrideOverride6": {
		"kind": "Override",
		"props": {
			"slot": "link-team",
			"children": "Team"
		}
	},
	"overrideOverride7": {
		"kind": "Override",
		"props": {
			"slot": "link-contact",
			"children": "Contact"
		}
	},
	"overrideOverride8": {
		"kind": "Override",
		"props": {
			"slot": "link-payment",
			"href": "https://kursin.bubbleapps.io/",
			"target": "_blank",
			"children": "Payment"
		}
	},
	"burgerMenuOverride1": {
		"kind": "Override",
		"props": {
			"slot": "icon,icon-close",
			"category": "md",
			"icon": MdMenu
		}
	},
	"burgerMenuOverride2": {
		"kind": "Override",
		"props": {
			"slot": "icon",
			"category": "md",
			"icon": MdMenu,
			"size": "36px"
		}
	},
	"burgerMenuOverride3": {
		"kind": "Override",
		"props": {
			"slot": "menu-open",
			"md-top": 0,
			"md-bottom": 0,
			"md-display": "flex",
			"md-flex-direction": "column",
			"md-align-items": "center",
			"md-justify-content": "center"
		}
	},
	"burgerMenuOverride4": {
		"kind": "Override",
		"props": {
			"slot": "icon-open",
			"md-position": "fixed",
			"md-top": "24px",
			"md-right": "calc(4% + 4px)"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			width="100%"
			margin="0px 32px 0px 32px"
			md-margin="0px 16px 0px 16px"
			min-width="auto"
		/>
		<Stack {...override("stack")}>
			{"    "}
			<StackItem {...override("stackItem")}>
				<Override {...override("stackItemOverride")} />
				{"        "}
				<Link {...override("link")}>
					<Text {...override("text")} />
				</Link>
			</StackItem>
			<StackItem {...override("stackItem1")}>
				<Override {...override("stackItemOverride1")} />
				{"        "}
				<BurgerMenu {...override("burgerMenu")}>
					<Override {...override("burgerMenuOverride")}>
						<Override {...override("overrideOverride")} />
						<Override {...override("overrideOverride1")} />
						<Override {...override("overrideOverride2")} />
						<Override {...override("overrideOverride3")} />
						<Override {...override("overrideOverride4")} />
						<Override {...override("overrideOverride5")} />
						<Override {...override("overrideOverride6")} />
						<Override {...override("overrideOverride7")} />
						<Override {...override("overrideOverride8")} />
					</Override>
					<Override {...override("burgerMenuOverride1")} />
					<Override {...override("burgerMenuOverride2")} />
					<Override {...override("burgerMenuOverride3")} />
					<Override {...override("burgerMenuOverride4")} />
				</BurgerMenu>
				{"    "}
			</StackItem>
			{"    "}
		</Stack>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;