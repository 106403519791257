import React from "react";
import { useOverrides, Override, StackItem, Stack, Section } from "@quarkly/components";
import { Text, Strong, Link } from "@quarkly/widgets";
const defaultProps = {
	"quarkly-title": "USP",
	"padding": "50px 0 50px 0",
	"lg-padding": "25px 0 25px 0",
	"lg-height": "auto",
	"justify-content": "center"
};
const overrides = {
	"stack": {
		"kind": "Stack",
		"props": {}
	},
	"stackItem": {
		"kind": "StackItem",
		"props": {
			"width": "100%",
			"display": "flex"
		}
	},
	"stackItemOverride": {
		"kind": "Override",
		"props": {
			"slot": "StackItemContent",
			"align-items": "center",
			"justify-content": "center",
			"height": "280px",
			"flex-direction": "column",
			"md-height": "360px",
			"padding": "36px 24px 36px 24px",
			"sm-height": "280px",
			"lg-height": "420px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "10px 0px 15px 0px",
			"font": "normal 300 38px/1.2 --fontFamily-googleMontserrat",
			"text-align": "center",
			"quarkly-title": "Title",
			"color": "--dark",
			"md-font": "--headline3",
			"children": "Start your business with us"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 28px 0px",
			"font": "--lead",
			"opacity": "0.6",
			"text-align": "center",
			"quarkly-title": "Description",
			"color": "--dark",
			"max-width": "720px",
			"children": <>
				Today you can implement your idea in a short time for{" "}
				<Strong
					data-q-widget-type="PRIMITIVE"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
				>
					really little money
				</Strong>
				.
			</>
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/contact",
			"text-decoration-line": "initial",
			"color": "--dark",
			"padding": "8px 18px 8px 18px",
			"font": "--base",
			"letter-spacing": "0.5px",
			"text-align": "center",
			"margin": "10px 0px 0px 0px",
			"background": "--color-light",
			"hover-transform": "translateY(-4px)",
			"transition": "transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "#04080c",
			"children": "Start working with us now"
		}
	}
};

const Upfooter = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			width="100%"
			margin="0px 32px 0px 32px"
			md-margin="0px 16px 0px 16px"
			min-width="auto"
		/>
		<Stack {...override("stack")}>
			{"    "}
			<StackItem {...override("stackItem")}>
				<Override {...override("stackItemOverride")} />
				{"        "}
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Link {...override("link")} />
				{"    "}
			</StackItem>
			{"    "}
		</Stack>
		{children}
	</Section>;
};

Object.assign(Upfooter, { ...Section,
	defaultProps,
	overrides
});
export default Upfooter;